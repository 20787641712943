@import "../base/variables";

.files-gallery {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    > .file-groups {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 16px;
        overflow-x: auto;
        > .fg-group {
            flex: 0 0 auto;
            padding: 4px 8px;
            border: 1px solid #e6e6e6;
            background-color: #fff;
            margin-right: 8px;
            cursor: pointer;
            user-select: none;
            &:hover {
                background-color: #eee;
            }
            &:active {
                background-color: #ddd;
            }
            &.active {
                background-color: $primary;
                color: #fff;
                &:hover {
                    background-color: $primary-focused;
                }
                &:active {
                    background-color: lighten($primary-focused, 3%);
                }
            }
        }
    }
    > .fg-item {
        display: flex;
        padding: 5px;
        width: 12.5%;
        > .fg-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            border-radius: 5px;
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0,0,0,0.5);
            position: relative;
            > .fg-thumb {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                cursor: pointer;
                &:hover {
                    opacity: 0.8;
                }
                &:active {
                    opacity: 0.5;
                }
                > img {
                    display: block;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: contain;
                }
                > svg {
                    margin: 2px;
                }
            }
            > .fg-info {
                padding: 5px 10px;
                overflow: hidden;
                > .fg-name {
                    white-space: nowrap;
                    font-weight: 600;
                    font-size: 12px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                > .fg-detail {
                    display: flex;
                    flex-wrap: wrap;
                    > .fg-date {
                        white-space: nowrap;
                        font-weight: 400;
                        font-size: 12px;
                    }
                    > .fg-size {
                        flex: 1;
                        text-align: end;
                        margin-left: 4px;
                        white-space: nowrap;
                        font-weight: 500;
                        font-size: 10px;
                    }
                }
                > .fg-del {
                    position: absolute;
                    bottom: -10px;
                    right: -10px;
                    width: 12px;
                    height: 12px;
                    border-radius: 6px;
                    box-shadow: 0 0 10px rgba(0,0,0,0.8);
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $danger;
                    transition-property: width, height, right, bottom, border-radius;
                    transition-duration: 200ms;
                    &:hover {
                        background-color: $danger-focused;
                    }
                    &:active {
                        box-shadow: 0 0 15px rgba(0,0,0,0.8);
                    }
                }
            }
            &:hover {
                > .fg-info > .fg-del {
                    bottom: 8px;
                    right: 8px;
                    width: 24px;
                    height: 24px;
                    border-radius: 12px;
                }
            }
            > .fg-selected {
                position: absolute;
                top: 8px;
                right: 8px;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                background-color: $primary;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #fff;
            }
        }
    }
    > .fg-del-all {
        position: fixed;
        bottom: 16px;
        right: 16px;
        width: 72px;
        height: 72px;
        border-radius: 36px;
        box-shadow: 0 0 10px rgba(0,0,0,0.8);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $danger;
        &:hover {
            background-color: $danger-focused;
        }
        &:active {
            box-shadow: 0 0 15px rgba(0,0,0,0.8);
        }
    }
    > .pagination {
        width: 100%;
    }
}
