.cinemaInfo {
    .bgImage {
        display: block;
        width: calc(100% + 42px);
        margin: -27px -21px 0;
    }
    .cinemaInfoHeader {
        position: relative;
    }
    .cinemaInfoMain {
        position: absolute;
        right: 0;
        bottom: 15px;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .cinemaTitle {
            font-size: 1.8em;
            line-height: 1.1em;
            color: white;
            font-weight: bold;
        }
        .cinemaAddress {
            font-size: 1em;
            line-height: 1em;
            color: white;
        }
        .cinemaPhone {
            font-size: 1.6em;
            line-height: 1em;
            font-weight: bold;
            color: #ff5f00;
        }
    }
    .infoPanel {
        background-color: #f6f6f6;
        margin: 0 -21px;
        color: #212121;
        overflow: hidden;
        border: 1px solid gray;
        &.inactive {
            border: 0;
        }
        .description {
            padding: 15px 21px;
            > p {
                white-space: pre-wrap;
            }
        }
    }
    .infoBtn {
        text-align: center;
        margin-top: -11px;
        cursor: pointer;
        > span {
            padding: 2px 25px;
            line-height: 0.8em;
            font-size: 0.8em;
            background: #0090C9;
            border-radius: 5px;
            color: #fafafa;
            &:focus, &:hover {
                outline: none;
                background: darken(#0090C9, 10);
            }
        }
    }
}
