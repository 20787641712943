$primary: #00587c;
$primary-focused: #1e6489;
$text-disabled: #777;
$danger: #d9534f;
$danger-focused: #c9302c;
$text-danger: #a94442;
$color-default: #fff;
$color-info: #3498db;
$color-success: #07bc0c;
$color-warning: #f1c40f;
$color-error: #e74c3c;

