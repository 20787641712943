@import "../base/variables";

.atmos {
    background-color: black;
    padding: 16px;
    color: white;
    h1 {
        text-align: center;
    }
}

.atmos-links {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 24px;
    justify-content: center;
    padding: 0;
    list-style-type: none;
    > li {
        margin: 0 8px;
        line-height: 21px;
        > a {
            color: #0098d7;
            font-weight: 400;
            border-bottom: 1px dotted;
            text-transform: uppercase;
            font-size: 16px;
            text-decoration: none;
            &:hover {
                color: $primary-focused;
                border-bottom: 1px solid transparent;
            }
            &.active {
                font-weight: 700;
                border-bottom: none;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.atmos-hall {
    p, strong {
        color: white;
    }
}

.atmos-showtimes {
    .cinemas {
        label {
            color: #0098d7;
        }
        .cinema-item {
            .cinema_title {
                border: 1px solid white;
            }
            .movies {
                .movie {
                    background-color: rgb(0, 35, 49);
                    .movie-name {
                        color: white;
                    }
                }
            }
        }
    }
}
