.footer {
    padding: 16px;
    background-color: #007EB0;
    font-size: 14px;
    border-top: 1px solid #d7d7d7;
    display: flex;
    .footer-links {
        flex: 1;
        color: white;
        a {
            color: white;
            font-weight: 500;
            text-decoration: none;
            &:hover {
                color: #ffd919;
            }
        }
    }
    .footer-brand {
        color: white;
    }
}