@import "../base/variables";
$border_color: #cdd0d2;

.imax-hall {
    .imax-repertory {
        width: 100%;
    }
}

.imax-showtimes {
    padding-top: 50px;
    .cinemas {
        label {
            font-size: 1.2em;
            font-weight: 500;
            color: #004e67;
            vertical-align: middle;
            margin-bottom: 10px;
        }
        .cinema-item {
            margin-bottom: 15px;
            box-shadow: 3px 3px 5px 0 rgba(0,0,0,.15);
            border-radius: 5px;
        }
    }
    .cinema_title {
        display: block;
        font-size: 1.1em;
        font-weight: 500;
        background: url('../../images/pixels.png') left no-repeat #b3e3f2;
        background-size: contain;
        color: #fff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
        text-decoration: none;
    }
    .movies {
        border: 1px solid $border_color;
        border-top: 0;
        border-radius: 0 0 5px 5px;
    }
    .movie {
        color: #263238;
        font-weight: 500;
        padding: 12px 10px;
        background: #f8f8f8;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height:100%;
        transition: background .4s;
        &:last-child {
            border-radius: 0 0 5px 5px;
            border-bottom: 0;
        }
        &:hover {
            background: #EBFAFF;
        }
    }
    .movie-name {
        width: 200px;
        float: left;
        > span {
            display: block;
            float: left;
            max-width: 100%;
            overflow: hidden;
        }
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
