@import "../base/variables";
.contacts {
    padding: 10px;
    label {
        display: block;
        color: #004e67;
        font-size: 2em;
        font-weight: 500;
        text-align: center;
    }
    hr {
        margin: 12px auto;
        border: none;
        background-color: #ddd;
        height: 1px;
        color: #ddd;
    }
    .title {
        display: block;
        color: #4b4b4b;
        font-size: 1.4em;
        text-align: left;
        padding: 10px 0;
        font-weight: 300;
    }
    .general_info {
        border: 1px dashed #b9b9b9;
        padding: 20px;
    }
    .cinemas_info {
        display: flex;
        justify-content: center;
        align-items: stretch;
        margin: 0 -5px;
        .cinema_item {
            position: relative;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            margin: 0 5px;
            border: 1px solid #0288D1;
            border-radius: 5px;
            .cinema_logo {
                flex: 1;
                display: flex;
                justify-content: center;
                padding: 5px 10px;
                img {
                    max-height: 50px;
                    max-width: 100%;
                }
            }
            .cinema_title {
                padding: 5px;
                text-align: center;
                font-size: 1em;
                font-weight: 500;
                background: #0277BD;
                border-radius: 0 0 4px 4px;
                color: white;
            }
            .cinema_selected {
                position: absolute;
                bottom: -25px;
                left: calc(50% - 12px);
                .glyphicon {
                    font-size: 20px;
                    color: $primary;
                }
            }
        }
    }
    .info {
        background-color: $primary;
        border-radius: 5px;
        margin-top: 14px;
        padding: 15px;
        .info_panel {
            display: flex;
            flex-direction: row;
            .info_side {
                padding: 10px;
                width: 300px;
                color: white;
                h3 {
                    font-weight: 500;
                }
                .details {
                    padding: 5px 0;
                    display: flex;
                    flex-direction: row;
                    .glyphicon {
                        font-size: 14pt;
                        padding: 0 10px 0 0;
                    }
                }
            }
            .info_map {
                flex: 1;
                border-radius: 5px;
            }
        }
    }
}
