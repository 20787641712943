@import "../base/variables";

.drop-zone {
    width: 100%;
    display: block;
    img{
        max-height:100px;
    }
}

.main-container {
}

#container {
    background-color: white;
    display: flex;
    flex-direction: row;
    > .content {
        flex: 1;
        max-width: calc(100% - 250px);
    }
    position: relative;
    height:100%;
    flex:1;
}
.logo-cinematica{
    display: block;
    width: 70%;
    margin-left: 38px;
    margin-bottom: 10px;
}
.side-nav-left {
    display: block;
    width: 250px;
    background-color: #ffffff;
    border-right: 0.1px solid rgba(213, 213, 213, 0.84);
    .menu-section {
        > h3 {
            font-size: 24px;
            font-weight: 700;
            color: lightgrey;
        }
        background-color: #ffffff;
    }

    .header {
        cursor: default;
        display: flex;
        justify-content: center;
        overflow: hidden;
        background-color: #ffffff;
        color: rgba(0, 0, 0, 0.6);
        font-size: 10px;
        width:80%;
        margin-left:23px;
        box-shadow: rgba(0,0,0,.2) 0 1px 0;
    }
    .text-disabled {
        user-select: none;
        position: relative;
        color: #5e5e5e;
        &:hover {
            display: block;
            color: rgba(#fff, 1);
            background-color: rgba(20, 113, 190, 0.71);
        }
    }

    .child_menu {
        display: block;
        height: auto;
        overflow: hidden;
        transition: all linear 3s;
        background-color: rgba(238, 238, 238, 0.71);
        > li > a {
            color: rgb(112, 112, 112);
            &.active {
                display: block;
                background-color: rgba(25, 121, 212, 0.38);
                color: rgba(59, 59, 59, 0.87);
                &:before{
                    content: "";
                    position: absolute;
                    top: 11px;
                    right: -3px;
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-right: 10px solid #f6f6f6;
                    border-bottom: 10px solid transparent;
                }
            }

        }
        &.collapse {
            height: 0;
        }
    }
}


.content {
    display: block;
    position: relative;
    padding: 15px;
    background-color: #f6f6f6;
}

form.table-filters {
    margin-bottom: 8px;
    &.form-inline .form-group {
        margin-right: 8px;
        > .control-label {
            margin-right: 4px;
        }
    }
    &.form-inline > .btn {
        margin-right: 8px;
    }
    .bordered {
        border: 1px solid #ccc;
        display: inline-block;
        padding: 4px;
        border-radius: 3px;
        margin-right: 4px;
    }
}
.colorTop{
    background-color: rgb(230, 230, 230);
    tr {
        th {
            border: 0 !important;
        }
    }
}
.colorTable{
    box-shadow:0 1px 4px rgb(165, 165, 165);
    background-color: #f3f3f3;
    td .checkbox {
        height: auto;
        padding: 0;
        margin-top: 0;
        margin-bottom: 0;
        label {
            display: block;
        }
    }
}
.btn-toolbar {
    margin-bottom: 4px;
}

.btn-toolbar > .link-btn {
    margin-left: 20px;
    display: block;
    float: left;
}

.form-group .read-only {
    padding-top: 7px;
    white-space: pre-wrap;
}

.side-menu, .child_menu {
    padding: 0;
    margin: 0;
    list-style: none;
}

.menu-item {
    display: block;
    padding: .5rem 1rem;

    &:hover, &:active {
        text-decoration: none;
        background-color: #eee;
    }
}

.form-group {
    margin-bottom: .5rem;

    &.row {
        .form-label {
            text-align: right;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}

.btn-toolbar > .btn + .btn {
  margin-left: 1rem;
}
