@import "../base/variables";

.newslist {
    max-width: 992px;
    hr {
        border: none;
        height: 1px;
        color: #ddd;
        background-color: #ddd;
        margin: 12px auto 20px;
    }
    label {
        font-size: 2em;
        font-weight: 500;
        color: #004e67;
        text-align: center;
        display: block;
    }
    .m-b-5{
        margin-bottom: 5px;
    }
    .news-grid {
        .news-item {
            margin: 0 7px 10px 7px;
            display:flex;
            > a {
                text-decoration: none;
                width: 220px;
                padding-right: 20px;
            }
            img {
                align-self: flex-start;
                width: 100%;
                height: auto;
                border-radius: 5px;
            }
            .news-content {
                flex: 1;
                .news-title {
                    color: #263238;
                    font-weight: 600;
                    &:hover {
                        color: #546E7A;
                    }
                }
                .news-body {
                    ul, ol {
                        margin: 10px 0 0 20px;
                        li {
                            list-style: square;
                        }
                    }
                }
            }
        }
    }
}