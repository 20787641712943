@import "variables";
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

html {
    font-family: 'Roboto', Helvetica, sans-serif;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
}

body {
    color: #666;
    line-height: 1.333;
}

h1 {
    margin-top: 0;
    font-size: 3.998em;
}

h1, h2 {
    &.page-title {
        color: $primary;
        font-size: 2em;
        font-weight: 300;
        text-transform: uppercase;
        border-bottom: 1px #f1f1f1 dotted;
        margin-bottom: 10px;
        padding-bottom: 10px;
    }
}

h3 {
    font-size: 1.999em;
}

h4 {
    font-size: 1.414em;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: rgba(#000, .9);
    &:hover {
        color: rgba(#000, 1);
    }
    &.active {
        color: rgba(#000, 1);
    }
}

.text-disabled {
    color: $text-disabled;
}
