$base-color: #29d;
$bar-z-index: 10002;
$icon-border-radius: 50%;

.loading {
    pointer-events: none;
    transition: 400ms linear all;
}

.bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $bar-z-index;
    display: none;
    width: 100%;
    height: 2px;
    background: $base-color;
    border-radius: 0 1px 1px 0;
    transition: width 350ms;
}

.peg {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 2px;
    border-radius: $icon-border-radius;
    opacity: .45;
    box-shadow: $base-color 1px 0 6px 1px;
}

.spinner {
    position: fixed;
    top: 5px;
    left: 5px;
    z-index: $bar-z-index;
    pointer-events: none;
    transition: 350ms linear all;
}

.icon {
    width: 14px;
    height: 14px;
    border: solid $base-color;
    border-width: 0 2px 2px 0;
    border-radius: $icon-border-radius;
    animation: loading-bar-spinner 400ms linear infinite;
}

@keyframes loading-bar-spinner {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}
