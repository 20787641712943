@import "../base/variables";

.cinemalist {
    hr {
        border: none;
        height: 1px;
        color: #ddd;
        background-color: #ddd;
        margin: 12px auto 20px;
    }
    label {
        font-size: 2em;
        font-weight: 500;
        color: #004e67;
        text-align: center;
        display: block;
    }
}
.cinema-list {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    .cinema-item {
        width: calc(100% / 3);
        padding: 10px;
        .cinemalist_dummy {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 200px;
            border: 1px solid #d7d7d7;
            border-radius: 5px;
            text-decoration: none !important;
            box-shadow: 3px 3px 8px 0 rgba(0,0,0,0.2);
            transform: scale(1, 1);
            transition: box-shadow .15s, transform .15s;
            overflow: hidden;
            &:hover {
                box-shadow: 5px 5px 14px 2px rgba(0, 0, 0, 0.13);
                transform: scale(1.02, 1.02);
            }
            .cinemalist_logo {
                width: 100%;
                height: 100%;
                padding: 20px;
                > img, > svg {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
                > svg {
                    fill: fade_out($primary, 0.9);
                }
            }
            .cinemalist_title {
                position: absolute;
                width: 100%;
                bottom: 10px;
                background-color: #1e6489;
                padding: 5px;
                p {
                    overflow: hidden;
                    font-size: 1.2em;
                    color: white;
                    margin: 0;
                }
            }
        }
    }
}