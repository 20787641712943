@import "../base/variables";

.topbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 99;
    display: block;
    overflow: hidden;
    width: 100%;
    background-color: rgba($primary, 0.9);
    -webkit-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.45);
    -moz-box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.45);
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.45);
    nav {
        display: flex;
        align-items: center;
        max-width: 1024px;
        margin: 0 auto;
        .show-menu-btn, .close-menu-btn {
            display: none;
            padding: 16px;
        }
        .menu-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 60px;
            width: 1024px;
            a {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 0 20px;
                color: rgba(#fff, .9);
                font-weight: 500;
                text-decoration: none;
                height: 100%;
                .cinematica-logo {
                    margin: 5px 0;
                    background: url('../../images/cinematica_horizontal.png') center no-repeat;
                    background-size: contain;
                    width: 200px;
                    height: 62px;
                    &.country-uz {
                        background: url('../../images/cinematica_horizontal.uz.png') center no-repeat;
                        background-size: contain;
                    }
                }
                .imax-btn {
                    background: url('../../images/imax_logo_white.png') center no-repeat;
                    background-size: contain;
                    width: 80px;
                    height: 30px;
                    &.country-uz {
                        background: url('../../images/imax_logo_white.uz.png') center no-repeat;
                        background-size: contain;
                    }
                }
                .atmos-btn {
                    background: url('../../images/atmos_logo_white.png') center no-repeat;
                    background-size: contain;
                    width: 80px;
                    height: 30px;
                    &.country-uz {
                        background: url('../../images/atmos_logo_white.uz.png') center no-repeat;
                        background-size: contain;
                    }
                }
                .vip-btn {
                    background: url('../../images/vip_logo_white.png') center no-repeat;
                    background-size: contain;
                    width: 80px;
                    height: 30px;
                }
                &:hover {
                    color: rgba(#fff, 1);
                    background: $primary-focused;
                }
                .glyphicon {
                    font-size: larger;
                    line-height: 1.3em;
                }
            }
            a:has(.vip-btn) {
                width: 120px;
                flex-direction: row;
                justify-content: center;
                gap: 4px;
                font-weight: bold;
                font-size: 24px;
                line-height: 24px;
            }
            a.active {
                background-color: #007EB0;
                color: white;
                .cinematica-logo {
                    background: url('../../images/cinematica_horizontal.png') center no-repeat;
                    background-size: contain;
                    &.country-uz {
                        background: url('../../images/cinematica_horizontal.uz.png') center no-repeat;
                        background-size: contain;
                    }
                }
            }
            a.active.no_background {
                background: none;
                &:hover {
                    background: $primary-focused;
                }
            }
        }
    }
    .sign_in_button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        padding: 0 24px;
        gap: 1rem;
        position: absolute;
        top: 0;
        right: 0;
        height: 60px;
        .button, a {
            color: rgba(#fff, .8);
            font-weight: 500;
            &:hover {
                color: #fff;
            }
        }
        a.active {
            text-decoration: underline;
        }
    }
}
