.table-condensed {
    font-size: 12px;
}

th a {
    user-select: none;
}

.caret.asc {
    transform: rotate(180deg);
}

.caret.desc {
    transform: rotate(0deg);
}