@import "../base/variables";
$border_color: #cdd0d2;

.cinema-showtimes {
    padding-top: 20px;
    .header {
        margin-bottom: 10px;
        .movie-select {
            padding: 5px;
            label {
                margin-right: 54px;
            }
        }
        label {
            font-size: 1.2em;
            font-weight: 500;
            color: #004e67;
            vertical-align: middle;
        }
    }
    .halls {
        .hall-item {
            margin-bottom: 15px;
            box-shadow: 3px 3px 5px 0 rgba(0,0,0,.15);
            border-radius: 5px;
        }
    }
    .hall_title {
        font-size: 1.1em;
        font-weight: 500;
        //background-color: #00759B;
        background: url('../../images/pixels.png') left no-repeat #b3e3f2;
        background-size: contain;
        color: #fff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_kfc {
        font-size: 1.1em;
        font-weight: 500;
        background: url('../../images/pixels_kfc.png') left no-repeat #ffffff;
        background-size: contain;
        color: #ffffff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_kfc_atmos {
        font-size: 1.1em;
        font-weight: 500;
        background: url('../../images/pixels_kfc_atmos.png') left no-repeat #ffffff;
        background-size: contain;
        color: #ffffff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_elcard_atmos {
        font-size: 1.1em;
        font-weight: 500;
        background: url('../../images/pixels_elcard_atmos.png') left no-repeat #ffffff;
        background-size: contain;
        color: #ffffff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_imax {
        background: url('../../images/pixels_imax.png') left no-repeat #98c9d8;
        background-size: contain;
        height: 43px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_vip1, .hall_title_vip2, .hall_title_vip_lounge {
        font-size: 1.1em;
        font-weight: 500;
        background: url('../../images/pixels_vip_lounge.png') left no-repeat #ffffff;
        background-size: contain;
        color: #ffffff;
        padding: 10px;
        border-radius: 5px 5px 0 0;
    }
    .hall_title_vip1 {
        background: url('../../images/pixels_vip1.png') left no-repeat #ffffff;
        background-size: contain;
    }
    .hall_title_vip2 {
        background: url('../../images/pixels_vip2.png') left no-repeat #ffffff;
        background-size: contain;
    }
    .movies {
        border: 1px solid $border_color;
        border-top: 0;
        border-radius: 0 0 5px 5px;
    }
    .movie {
        color: #263238;
        font-weight: 500;
        padding: 12px 10px;
        background: #f8f8f8;
        border-bottom: 1px solid #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height:100%;
        transition: background .4s;
        &:last-child {
            border-radius: 0 0 5px 5px;
            border-bottom: 0;
        }
        &:hover {
            background: #EBFAFF;
        }
    }
    .movie-name {
        width: 200px;
        float: left;
        > span {
            display: block;
            float: left;
            max-width: 100%;
            overflow: hidden;
            //text-overflow: ellipsis;
            //white-space: nowrap;
        }
        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.tooltip.no-opacity {
    opacity: 1;
}
