.faq {
    padding: 10px;
    .questions {
        padding-top: 10px;
        list-style-type: square;
        color: #0f6588;
        li {
            font-size: 1.2em;
            line-height: 1.6em;
            a {
                color: #0a455c;
                &:hover, &:focus, &:active {
                    text-decoration: none;
                    color: #0288d1;
                }
            }
        }
    }
}

.faq-answer {
    padding-bottom: 20px;
    &:after {
        clear: both;
        content: ' ';
        display: block;
    }
    > p img {
        max-width: 100%;
        object-fit: contain;
    }
}

$go-back-color: #0f6588;

.faq-go-back {
    margin-top: 8px;
    border-radius: 5px;
    border: 1px solid $go-back-color;
    background-color: #ffffff;
    color: $go-back-color;
    padding: 8px 15px;
    &:hover {
        background-color: lighten($go-back-color, 5%);
        color: #ffffff;
        text-decoration: none;
    }
    &:active {
        background-color: lighten($go-back-color, 10%);
        color: #ffffff;
        text-decoration: none;
    }
}

@media screen and (max-width: 425px) {
    .faq {
        .questions {
            padding: 5px;
            li {
                font-size: 1em;
                line-height: 1.2em;
                padding: 3px 0;
            }
        }
    }
}
