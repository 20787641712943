@import "../base/variables";

.wide-select {
    padding: 0 5px;
    label {
        margin-right: 8px;
    }
    a {
        margin: 0 8px;
        text-decoration: underline;
        user-select: none;
        &.active {
            text-decoration: none;
            color: $primary;
        }
    }
    .btn {
        margin: 5px 3px;
        box-shadow: none;
        &.btn-primary {
            background: #0275d8;
            border-color: #0275d8;
            &:hover {
                background: darken(#0275d8, 5);
            }
        }
        &.btn-default {
            background: #fff;
            &:hover {
                background: darken(#fff, 5);
            }
        }
        &:focus {
            outline: none;
        }
    }
}