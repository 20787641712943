@media screen and (max-width: 1024px) {
    body {
        background-position-y: 56px;
    }
    .wrapper {
        margin: 56px 0 0 0;
        .b-fixed-social {
            display: none;
        }
    }
    .home header {
        border-top: 1px solid white;
    }
    .home main {
        flex-direction: column;
        .announce-main {
            border-left: none;
        }
    }
    .movies-main {
        .movies-tabs .nav-tabs {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            padding-bottom: 1px;
            border: none;
            .nav-item {
                flex: 0 0 auto;
                margin-bottom: 0;
                .nav-link {
                    margin-right: 0;
                    border: 1px solid white;
                    border-radius: 4px;
                    &.active  {
                        border: 1px solid #1E88E5;
                    }
                }
            }
        }
        .tab-content {
            border: none;
            padding: 0;
        }
    }
    .movies-grid .movie-dummy {
        width: calc(100% / 3);
    }
    .announce-main {
        max-width: 100%;
        h4, hr, .announce-all-link {
            width: 100%;
        }
        .announce-body {
            flex-direction: row;
            overflow-x: scroll;
            align-items: stretch;
            .announce-item {
                display: flex;
                flex: 0 0 auto;
                width: 25%;
                padding: 10px;
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .announce-title {
                        height: 2em;
                    }
                }
            }
        }
        .announce-all-link {
            margin-top: 20px;
            a {
                font-size: 14pt;
                font-weight: 500;
                text-decoration: underline;
                color: #145397;
                &:hover {
                    color: #1976D2;
                }
            }
        }
    }
    .topbar {
        height: 100%;
        overflow: hidden;
        &.menu-hidden {
            height: 56px;
            .menu-buttons, .close-menu-btn {
                display: none;
            }
        }
        nav {
            height: 100%;
            flex-direction: column;
            align-items: flex-start;
            .show-menu-btn {
                display: block;
            }
            .close-menu-btn {
                flex: 1;
                align-self: stretch;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .menu-buttons {
                flex-direction: column;
                align-items: stretch;
                width: 100%;
                height: auto;
                border-bottom: 1px solid white;
                a {
                    padding: 16px;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    border-top: 1px solid white;
                    .cinematica-logo, .imax-btn, .atmos-btn, .vip-btn {
                        width: 100%;
                        height: 24px;
                        margin: 0;
                        background-position: left !important;
                    }
                    > svg {
                        margin-right: 8px;
                    }
                }
            }
        }
        .sign_in_button {
            height: auto;
            padding: 16px;
            .button {
                display: block;
                height: 24px;
                line-height: 24px;
                font-size: 16px;
            }
        }
    }
    .footer {
        flex-direction: column;
        .footer-links {
            display: flex;
            flex-wrap: wrap;
        }
        .footer-brand {
            margin-top: 5px;
        }
    }
    .times {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        width: auto;
        .time {
            position: relative;
            top: 0;
            left: 0;
            margin-right: 8px;
        }
    }
    .imax-showtimes .movie, .vip-showtimes .movie, .movie-showtimes .hall, .cinema-showtimes .movie {
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        .movie-name, .hall-name {
            min-width: 50%;
            max-width: 50%;
            width: auto;
            margin-right: 8px;
        }
        .times {
            flex: 0 0 auto;
        }
    }
    .posts {
        padding: 16px;
        hr {
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }
    .page {
        overflow-x: auto;
        border-radius: 0;
    }
    .cinema-list .cinema-item {
        width: 50%;
    }
    .newslist .news-grid .news-item {
        margin: 0;
        > a {
            width: calc(100% / 3);
            max-width: 208px;
            padding-right: 8px;
        }
    }
    .cinemaInfo {
        .bgImage {
            margin: -15px -15px 0;
            width: calc(100% + 36px);
        }
        .infoPanel {
            margin: 0 -15px;
        }
    }
    .wrapper.admin-wrapper {
        min-width: 1024px;
    }
    .subscribe {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .form {
            align-self: stretch;
            margin: 0;
            width: auto;
        }
    }
}

@media screen and (max-width: 767px) {
    .wrapper .banner-wrapper {
        display: none;
    }
    .home header {
        border-top: none;
        .quick_pay {
            display: none;
        }
    }
    .movies-grid .movie-dummy {
        width: 50%;
    }
    .announce-main {
        max-width: 100%;
        h4, hr, .announce-all-link {
            width: 100%;
        }
        .announce-body {
            flex-direction: row;
            overflow-x: scroll;
            align-items: stretch;
            .announce-item {
                display: flex;
                flex: 0 0 auto;
                width: 40%;
                padding: 10px;
                > a {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .announce-title {
                        height: unset;
                    }
                }
            }
        }
    }
    .imax {
        flex: 1;
    }
    .movieDetails {
        padding: 8px 0;
    }
    .movieInfo {
        .movieDetails {
            padding: 8px;
        }
        .infoBlock {
            .title {
                display: inline-block;
            }
            .buttons {
                position: relative;
                float: right;
                padding: 0 0 0 8px;
            }
        }
    }
    .panel.movieDetails .panel-body > div:not(.title) {
        margin: 0 -15px;
    }
    .cinema-list .cinema-item {
        width: 100%;
        padding: 5px;
        .cinemalist_dummy {
            height: 150px;
        }
    }
    .cinemaInfo {
        .cinemaInfoMain {
            position: relative;
            flex-direction: column;
            align-items: stretch;
            bottom: 0;
            margin: 0 0 8px;
            .cinemaTitle, .cinemaAddress {
                color: #333;
                margin: 4px 0;
            }
        }
        .infoPanel.inactive {
            border-top: 1px solid gray;
        }
    }
    .contacts {
        .cinemas_info {
            justify-content: flex-start;
            overflow-x: auto;
            .cinema_item {
                flex: 0 0 auto;
                .cinema_selected {
                    display: none;
                }
            }
        }
        .info {
            .info_panel {
                flex-direction: column;
            }
        }
    }
    .payment .ticketBlock {
        flex-direction: column;
    }
    .payment .ticketBlock .ticketInfo .row div:first-child {
        text-align: left;
    }
    .payment .tab-content .demirbank .logos {
        flex-wrap: wrap;
        img {
            margin: 8px;
        }
    }
    .modal-container .movieScreenShots {
        div {
            margin: 0;
        }
        > div .react-blueimp-thumbnails {
            flex-direction: column;
            a {
                width: 100%;
            }
        }
    }
    .home header .carousel .movie-name {
        font-size: 12pt;
    }
}
