/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	-webkit-font-smoothing: antialiased;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
iframe {
    border: none;
}
figure.image > img {
	width: 100%;
	height: auto;
}