.home {
    header {
        position: relative;
        background-color: #ffffff;
        h1 {
            font-weight: 700;
            color: #000;
        }
        h4 {
            color: rgba(#000, .5);
            font-weight: 400;
        }
        .carousel-inner {
            height: 100%;
            .carousel-item {
                height: 100%;
                .img-poster {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .movie-name {
                position: absolute;
                top: 10%;
                left: 10%;
                color: #ffffff;
                font-size: 24pt;
                font-weight: bold;
                text-shadow: 2pt 2pt 20pt #000;
            }
        }
    }
    main {
        display: flex;
        flex-flow: row;
        width: 100%;
        hr {
            border: none;
            height: 1px;
            color: #ddd;
            background-color: #ddd;
            margin: 12px auto 12px;
        }
    }
}

.quick_pay {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 88;
    &.open {
        height: 100%;
        border-bottom: 1px solid #ddd;
        background-color: rgba(1, 1, 1, .9);
    }
    .quick_pay_menu {
        display: flex;
        align-items: center;
        height: 36px;
        font-size: 10pt;
        line-height: 1;
        font-weight: 500;
        color: #fff;
        background-color: rgba(1, 1, 1, .5);
        gap: 0.25rem;
        padding: 0.25rem;
        > * {
            flex: 1 1 auto;
        }
        > div:hover {
            color: #f1c40f;
            cursor: pointer;
            .imax-btn {
                display: inline-block;
                background: url('../../images/imax_logo_small_blue.png') center no-repeat;
                background-size: contain;
                width: 73px;
                height: 12px;
            }
        }
        .qpm_active {
            color: #f1c40f;
            .imax-btn {
                display: inline-block;
                background: url('../../images/imax_logo_small_blue.png') center no-repeat;
                background-size: contain;
                width: 73px;
                height: 12px;
            }
        }
        .imax-btn {
            display: inline-block;
            background: url('../../images/imax_logo_small.png') center no-repeat;
            background-size: contain;
            width: 73px;
            height: 12px;
        }
    }
    .quick_pay_body {
        display: flex;
        height: calc(100% - 36px);
        color: #fff;
        .qp_main {
            flex: 1;
            padding: 10px 20px;
            overflow-y: scroll;
            .qp_close {
                display: inline-block;
                float: right;
                font-size: 16pt;
                opacity: .8;
                &:hover {
                    opacity: 1;
                }
                &:active {
                    color: #b71c1c;
                }
            }
            .qp_movies {
                margin-top: 10px;
                border: 1px solid #4b4b4b;
                .qp_movies_item {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 0 5px 0 20px;
                    border-bottom: 1px solid #4b4b4b;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &:hover {
                        background-color: #191919;
                    }
                    .qp_flex1 {
                        flex: 1;
                    }
                    .qp_flex2 {
                        flex: 3;
                        margin-left: 20px;
                        color: #fff;
                    }
                    .qp_discount {
                        margin-left: 5px;
                        font-size: 12pt;
                        opacity: .6;
                        &:hover {
                            opacity: 1;
                        }
                        &:active {
                            color: #b71c1c;
                        }
                    }
                    a {
                        text-decoration: none;
                        .qp_btn {
                            margin: 3px;
                            background: #0275d8;
                            border-color: #0275d8;
                            padding: 3px 7px;
                            font-size: 9pt;
                            color: #fff;
                            border-radius: 3px;
                            &:hover {
                                background: darken(#0275d8, 5);
                            }
                            &:focus {
                                outline: none;
                            }
                        }
                    }
                }
            }
        }
        .qp_desc {
            padding: 5px;
            width: 199px;
            overflow: hidden;
        }
    }
}

.qp_desc {
    .qp_poster {
        position: relative;
        display: block;
        overflow: hidden;
        height: 0;
        padding: 133.33% 0 0;
        margin-bottom: 5px;
    }
    .qp-movie-poster {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .movie-type {
        position: absolute;
        right: 0;
        top: 0;
        width: 56px;
        height: 56px;
        object-fit: contain;
    }
    .qp_desc_title {
        font-size: 12pt;
        font-weight: 600;
        color: #f1c40f;
        text-align: center;
    }
    .qp_desc_text {
        font-size: 10pt;
        margin-top: 5px;
    }
    .qp_desc_notext {
        border: 1px solid #ebebeb;
        border-radius: 5px;
        padding: 50px 10px;
        margin-top: 10px;
        text-align: center;
        font-size: 14pt;
        font-weight: 500;
        color: #FFCCBC;
    }
}

.movies-main {
    flex: 1;
    padding: 10px;
    .movies-tabs .nav-item {
        font-size: 12pt;
        font-weight: 600;
        .nav-link {
            background: #1976D2;
            color: #fff;
            border: 1px solid #ddd;
            border-bottom-color: transparent;
            &:hover {
                background-color: #1E88E5;
            }
            &.active {
                background: #fff;
                color: #1976D2;
            }
        }
    }
    .tab-content {
        padding: 15px;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
    }
}

.poster-link {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
