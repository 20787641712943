@use "sass:math";
@import "variables";

html {
    box-sizing: border-box;
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    background: #e7e7e7 no-repeat top center fixed;
    min-height: 100%;
    background-position-y: 60px;
    -webkit-font-smoothing: antialiased;
}

.bannerLink, .bannerVideo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.wrapper {
    max-width: 1024px;
    margin: 60px auto 10px auto;
    .banner-wrapper {
        display: flex;
        position: relative;
        height: 250px;
    }
    .video-js {
        .vjs-tech {
            width: 100%;
        }

        .vjs-big-play-button {
            top: calc(50% - 0.75em);
            left: calc(50% - 1.5em);
        }
    }
    .toastify {
        z-index: 10000;
    }
}

.wrapper.admin-wrapper {
    max-width: 100%;
    margin: 0;
    .topbar, .banner-wrapper, .b-fixed-social {
        display: none !important;
    }
}

.wrapper.file-browser {
    .side-nav-left, .footer {
        display: none;
    }
}

.bannerVideo {
    display: flex;
    justify-content: center;
    align-items: center;
    .video-js {
        background-color: transparent;
        width: auto;
        height: 100%;
        .vjs-tech {
            position: relative;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.embed-responsive {
    .video-js {
        position: absolute;
        width: 100%;
        height: 100%;
    }
}

#root, .wrapper, .page, .authComponent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

body, #root, .wrapper {
    width: 100%;
}

footer {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    padding: 18px 0;
    border-top: 1px solid #eee;
    font-size: 13px;
    color: #666;
    a {
        margin-left: .5ch;
        &:first-child {
            margin-right: .5ch;
        }
    }
}

.mt-4 {
    margin-top: 1rem;
}

.margin-bottom {
    margin-bottom: 8px;
}

.mv-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
}

.mv-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.mv-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
}

.mv-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.padding {
    padding: 16px;
}

.times {
    display: block;
    float: left;
    width: calc(100% - 244px);
    position: relative;

    .time {
        position: absolute;
        display: inline-flex;
        border-radius: 4px;
        overflow: hidden;
        align-items: center;
        top: -15px;
        outline: none;
        text-decoration: none;
        > span {
            display: block;
            color: white;
            padding: 5px 10px;
            font-size: 14px;
            background-color: #00838F;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    $t: math.div(100, 18);
    $ts: math.div($t, 12);
    @for $i from 0 through 18 {
        @for $j from 0 through 11 {
            @if $i < 10 and $j < 2 {
                .time-0#{$i}-0#{ $j * 5} {
                    left: 0% + ($t * $i) + ($ts * $j);
                }
            } @else if $i < 10 {
                .time-0#{$i}-#{ $j * 5} {
                    left: 0% + ($t * $i) + ($ts * $j);
                }
            } @else if $j < 2 {
                .time-#{$i}-0#{ $j * 5} {
                    left: 0% + ($t * $i) + ($ts * $j);
                }
            } @else {
                .time-#{$i}-#{ $j * 5} {
                    left: 0% + ($t * $i) + ($ts * $j);
                }
            }
        }
    }
}

.tooltip-image {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

a[disabled][href] {
    pointer-events: none;
}

.block {
    display: block;
}

.block.center {
    margin: 0 auto;
}

.not-found {
    margin-top: 50px;
    text-align: center;
    border: 2px dashed #d7d7d7;
    padding: 50px 10px;
    .glyphicon {
        display: block;
        color: #c62828;
        font-size: 80pt;
        margin-bottom: 20px;
    }
    label {
        color: #00695C;
        font-size: 60pt;
        font-weight: 500;
    }
    p {
        color: #37474F;
        font-size: 20pt;
        margin-bottom: 100px;
    }
    .feedbackBtn {
        background: #01579B;
        color: white;
        font-weight: 500;
        padding: 10px 20px;
        border-radius: 7px;
        &:hover {
            text-decoration: none;
            background: #0277BD;
        }
    }
}

.flex {
    flex: 1;
}

.text-white {
    color: white
}

.bg-error {
    background-color: $color-error;
}

.status-success {
    color: white;
    background-color: $color-success;
    padding: 2px 5px;
    border-radius: 5px;
}

.status-fail {
    color: white;
    background-color: $color-error;
    padding: 2px 5px;
    border-radius: 5px;
}

.status-pending {
    color: white;
    background-color: $color-warning;
    padding: 2px 5px;
    border-radius: 5px;
}

.padding-top {
    padding-top: 8px;
}

.price-som {
    font-weight: 500;
    text-decoration: underline !important;
}

.border-dashed {
    --bs-border-style: dashed;
}