.payment {
    label {
        display: block;
        color: #004e67;
        font-size: 2em;
        font-weight: 500;
        text-align: center;
    }
    hr {
        margin: 12px auto;
        border: none;
        background-color: #ddd;
        height: 1px;
        color: #ddd;
    }
    .btn-default, .btn-primary, .btn-warning, .btn-danger, .btn-success {
        &, &:hover, &:focus, &:active {
            text-shadow: none;
            border: 0;
            font-weight: 500;
            font-size: 12pt;
            padding: 8px 50px;
        }
        &[disabled] {
            opacity: 0.8;
        }
    }
    .btn-default, .btn-primary {
        background: #009688 none;
        color: white;

        &:not([disabled]) {
            &:hover, &:focus, &:active {
                color: white;
                background: #26A69A;
            }
        }
    }
    .ticketBlock {
        padding: 10px;
        display: flex;
        .ticketImg {
            width: 200px;
            img {
                width: 100%;
            }
        }
        .ticketInfo {
            flex: 2;
            padding: 10px;
            border: 2px dashed gray;
            border-radius: 10px;
            color: black;
            .row {
                div:first-child {
                    font-weight: 500;
                    text-align: right;
                }
                .amount {
                    font-weight: 500;
                }
            }
        }
    }
    .method {
        padding: 5px;
        background-color: #00838F;
        margin-bottom: 5px;
        h4 {
            text-align: center;
            color: #fff;
        }
    }
    .tab-content {
        label {
            font-size: 12pt;
            font-weight: normal;
            color: #373737;
        }
        .demirbank,.payze {
            margin: 30px 0 20px 0;
            display: flex;
            .logos {
                flex: 1;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
                img {
                    object-fit: contain;
                    max-height: 40px;
                    margin: 0 8px;
                }
            }
        }
        .demir_logo {
            margin: 20px auto 10px;
            width: 200px;
            height: 70px;
        }
        .mbank_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/mbank_logo.png') center no-repeat;
            background-size: contain;
            width: 200px;
            height: 70px;
        }
        .balance_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/balance_logo.png') center no-repeat;
            background-size: contain;
            width: 200px;
            height: 70px;
        }
        .megapay_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/MegaPayLogo.png') center no-repeat;
            background-size: contain;
            width: 220px;
            height: 90px;
        }
        .elsom_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/elsom.png') center no-repeat;
            background-size: auto;
            width: 200px;
            height: 100px;
        }
        .elsom_info {
            padding: 5px 25px;
            font-size: 0.9em;
        }
        .elsom_confirm {
            .title {
                text-align: center;
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 1.4em;
            }
            .code {
                padding: 15px 10px;
                margin: 0 20px 10px;
                border: 1px solid #4ABDAC;
                border-radius: 7px;
                color: #0375B4;
                text-align: center;
                font-size: 2.4em;
                font-weight: 600;
                background-color: #fafafa;
            }
            .info {
                margin: 0 20px 30px;
                text-align: center;
            }
        }
        .elsom_buttons {
            display: flex;
            flex-flow: row wrap;
        }
        .payze_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/payze.png') center no-repeat;
            background-size: contain;
            width: 200px;
            height: 70px;
        }
        .dis_logo {
            margin: 20px auto 10px;
            background: url('../../../resources/logos/dis.png') center no-repeat;
            background-size: contain;
            width: 200px;
            height: 70px;
        }
        .conditions {
            color: #4459d9;
            font-weight: 600;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .message {
        display: inline-flex;
        flex-direction: column;
        margin: 15px auto;
        padding: 15px 40px;
        border: 2px solid #c3c3c3;
        border-radius: 5px;
        background: #f3f6f8;
        .glyphicon {
            font-size: 36pt;
            margin-bottom: 10px;
        }
        label {
            font-size: 14pt;
            font-weight: 500;
            margin-bottom: 10px;
        }
        &.success {
            .glyphicon {
                color: #2E7D32;
            }
            label {
                color: #2E7D32;
            }
            .ticketNo {
                font-weight: bold;
                color: #AD1457;
            }
        }
        &.fail {
            .glyphicon {
                color: #c62828;
            }
            label {
                color: #c62828;
            }
        }
        button {
            margin-top: 15px;
        }
    }
}
.print_area {
    display: none;
    border: 1px dashed #808080;
    padding: 25px;
    .printCinematicaInfo {
        font-size: 8pt;
        text-align: left;
        border-bottom: 1px solid gray;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .printPaymentInfo {
        font-size: 14pt;
        font-weight: bold;
        margin-bottom: 8px;
        text-align: center;
    }
    .printMovieInfo {
        label {
            width: 220px;
            font-weight: 500;
        }
        .movieTitle {
            font-size: 130%;
            font-weight: 500;
        }
    }
}

@media print {
    .payment {
        display: none;
    }
    .topbar {
        display: none;
    }
    .bannerLink {
        display: none;
    }
    .b-fixed-social {
        display: none;
    }
    .footer {
        display: none;
    }
    .print_area {
        display: inline-block;
        position: absolute;
        top: 20px;
        left: 20px;
    }
}
